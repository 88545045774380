<template>
  <div class="fll--viewport origin">
    <p class="abs--center-center">404</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>